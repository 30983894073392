<template>
    <layout-full-width :title="$tc('segment', 2)">
        <component-actions :action-filters="[]"
                           :bulk="bulk"
                           :extended-search="extendedSearch"
                           :general-search-fields="generalSearchFields"
                           :itemsPerPage="itemsPerPage"
                           :page="page"
                           :primary-actions="primaryActions"
                           :total="total"
                           @applyFilters="onApplyFilters"
                           @changeItemsPerPage="onChangeItemsPerPage"
                           @paginate="onChangePage"
                           @refresh="listSegments"/>

        <div class="row">
            <div class="col-12">
                <div class="table-responsive">
                    <table :class="{'table-hover': !loading.SEGMENT_LIST}" class="table">
                        <thead>
                        <component-table-header :heading-columns="headingColumns"
                                                :select-all-status="getSelectAllStatus('segments')"
                                                :show-checkbox="true"
                                                @sort="onSortSegments"
                                                @toggleCheckbox="toggleCheckbox('ALL', 'segments')"/>
                        </thead>
                        <tbody>
                        <tr v-if="segments.total === 0 && !loading.SEGMENT_LIST">
                            <td colspan="99">
                                <div
                                    class="py-3 text-primary d-flex align-items-center justify-content-center flex-column mb-0"
                                    role="alert">
                                    <font-awesome-icon icon="exclamation-triangle" size="3x"/>
                                    <div class="mt-2 fs-4">{{ $t('pages.segments.no-segments-found') }}</div>
                                </div>
                            </td>
                        </tr>
                        <template v-if="segments.total > 0 && !loading.SEGMENT_LIST">
                            <tr v-for="(segment, i) in segments.list" :key="'segment-' + i"
                                class="pointer"
                                @click="onClickSegment($event, segment)">
                                <td class="align-middle">
                                    <button class="btn btn-checkbox" @click="toggleCheckbox(i, 'segments')">
                                        <font-awesome-icon
                                            :class="segment.selected ? 'text-primary' : ''"
                                            :icon="segment.selected ? ['fas', 'check-square'] : ['far', 'square']"
                                            class="pe-none"/>
                                    </button>
                                </td>
                                <td class="align-middle">{{ segment.segmentDescShort }}</td>
                                <td class="align-middle">
                                    <template v-if="segment.meta_update_dt">
                                        {{ segment.meta_update_dt | timestamp }}
                                    </template>
                                    <template v-else>
                                        {{ segment.meta_insert_dt | timestamp }}
                                    </template>
                                </td>
                                <td class="align-middle">
                                    <div class="dropdown">
                                        <button :id="'dropdown-' + i"
                                                aria-expanded="false"
                                                aria-haspopup="true"
                                                class="btn p-0 bg-transparent"
                                                data-bs-toggle="dropdown"
                                                type="button">
                                            <font-awesome-icon class="pe-none" icon="ellipsis-h"/>
                                        </button>
                                        <div :aria-labelledby="'dropdown-' + i"
                                             class="dropdown-menu dropdown-menu-right">
                                            <button class="dropdown-item"
                                                    @click="onDeleteSegment(segment.segmentUniqueId)">
                                                {{ $t('actions.delete') }}
                                            </button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </template>
                        <template v-if="loading.SEGMENT_LIST">
                            <tr v-for="n in itemsPerPage" :key="'row-shimmer-' + n">
                                <td class="align-middle">
                                    <component-shimmer height="14px" style="margin: 4px 0;" width="14px"/>
                                </td>
                                <td class="align-middle">
                                    <component-shimmer/>
                                </td>
                                <td class="align-middle">
                                    <component-shimmer/>
                                </td>
                                <td class="align-middle">
                                    <component-shimmer width="20px"/>
                                </td>
                            </tr>
                        </template>
                        </tbody>
                    </table>
                </div>
            </div>

            <div id="new-segment" ref="newSegment"
                 aria-hidden="true"
                 aria-labelledby="dialogNewSegment"
                 class="modal fade"
                 role="dialog"
                 tabindex="-1">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">{{ $tc('new-segment', 1) }}</h5>
                            <button :aria-label="$t('actions.close')" class="btn-close" data-bs-dismiss="modal"
                                    type="button"></button>
                        </div>
                        <div class="modal-body">
                            <form @submit.prevent="onAddSegment">
                                <label class="d-block mb-2" for="new-segment-name">
                                    <strong>{{ $tc('segment-name', 1) }}</strong>
                                </label>
                                <div class="input-group">
                                    <input id="new-segment-name"
                                           v-model="segmentDescShort"
                                           class="form-control"
                                           name="segment_name"
                                           required="required"
                                           type="text">
                                </div>
                                <button :disabled="segmentDescShort === ''"
                                        class="btn btn-primary mt-2 float-end"
                                        type="submit">
                                    {{ $t('actions.add-segment') }}
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <component-update-segment :active="update.active"
                                      size="md"
                                      @close="update.active = false;"
                                      @update="onUpdateSegment"/>
        </div>
    </layout-full-width>
</template>

<script>
import LayoutFullWidth from "../../layouts/LayoutFullWidth";
import ComponentActions from "../../components/ui/ComponentActions";
import ComponentShimmer from "../../components/ComponentShimmer";
import ComponentUpdateSegment from "../../components/ComponentUpdateSegment";
import ComponentTableHeader from '../../components/ui/ComponentTableHeader.vue';
import closeModal from "../../lib/close-modal";
import shouldOpenSlideIn from "../../lib/should-open-slide-in";
import {countries} from "countries-list";

export default {
    name: "PageSegments",
    components: {
        ComponentUpdateSegment,
        ComponentShimmer,
        ComponentActions,
        LayoutFullWidth,
        ComponentTableHeader
    },
    data() {
        return {
            countries,
            primaryActions: [
                {
                    text: this.$t('actions.new'),
                    icon: 'plus',
                    type: 'modal',
                    target: '#new-segment'
                }
            ],
            generalSearchFields: ['gen_segment_desc_short'],
            extendedSearch: [
                {
                    name: this.$tc('name', 1),
                    technicalName: 'gen_segment_desc_short'
                }
            ],
            headingColumns: [
                {
                    title: this.$tc('name', 1),
                    sortField: 'gen_segment_desc_short'
                },
                {
                    title: this.$t('modified')
                },
                {
                    title: ""
                }
            ],
            bulk: false,
            filter: null,
            order: {
                meta_insert_dt: "DESC"
            },
            page: 1,
            itemsPerPage: 10,
            total: 0,
            segmentDescShort: "",
            update: {
                active: false
            }
        }
    },
    computed: {
        listSegmentsPayload() {
            return {
                filter: this.filter,
                order: this.order,
                page: this.page,
                itemsPerPage: this.itemsPerPage
            }
        },
        segment() {
            return this.$store.state.SEGMENT.segment;
        },
        segments() {
            return this.$store.state.SEGMENT.segments;
        },
        contacts() {
            return this.$store.state.CONTACT.contacts;
        },
        loading() {
            return {
                SEGMENT_LIST: this.$store.state.loading.SEGMENT_LIST
            }
        }
    },
    methods: {
        listSegments() {
            this.$store.dispatch('SEGMENT/LIST', {
                filter: this.filter,
                order: this.order,
                page: this.page,
                itemsPerPage: this.itemsPerPage
            })
                .then(segments => {
                    this.total = segments.total;
                });
        },
        onAddSegment() {
            this.$store.dispatch('SEGMENT/CREATE', {
                segmentDescShort: this.segmentDescShort,
                segmentDescLong: null,
                segmentComment: null
            })
                .then(() => {
                    this.$store.dispatch('toast/success', {
                        title: this.$tc('toast.success.success'),
                        message: this.$tc('toast.success.segment-created')
                    });
                    closeModal(this.$refs.newSegment);
                    this.listSegments()
                    this.$gtag.event('SEGMENT_CREATE');
                })
                .catch(error => {
                    this.$store.dispatch('toast/danger', {
                        title: this.$tc('toast.danger.error'),
                        message: error.response.data.message
                    });
                });
        },
        onUpdateSegment() {
            this.$store.dispatch('toast/success', {
                title: this.$tc('toast.success.success'),
                message: this.$tc('toast.success.segment-updated')
            });
            this.listSegments();
        },
        onDeleteSegment(segmentUniqueId) {
            this.$store.commit('loading/SEGMENT_LIST', true);
            this.$store.dispatch('SEGMENT/LIST', {segmentUniqueId})
                .then(() => {
                    this.$store.dispatch('toast/success', {
                        title: this.$tc('toast.success.success'),
                        message: this.$tc('toast.success.segment-deleted')
                    });
                    this.listSegments();
                })
                .catch(error => {
                    this.$store.dispatch('toast/danger', {
                        title: this.$tc('toast.danger.error'),
                        message: error.response.data.message
                    });
                });
        },
        onChangePage(page) {
            if (page <= 0) {
                return;
            }

            this.page = parseInt(page, 10);
            this.listSegments();
        },
        onSortSegments(order) {
            this.order = order;
            this.listSegments();
        },
        onChangeItemsPerPage(itemsPerPage) {
            if (this.itemsPerPage === itemsPerPage) {
                return;
            }

            this.page = 1;
            this.itemsPerPage = itemsPerPage;
            this.listSegments();
        },
        onApplyFilters(filters) {
            this.filter = filters;
            this.listSegments();
        },
        async toggleCheckbox(i, objectName) {
            if (i === 'ALL') {
                if (this.getSelectAllStatus(objectName)) {
                    this[objectName].list.forEach(object => {
                        object.selected = false;
                    })
                } else {
                    this[objectName].list.forEach(object => {
                        object.selected = true;
                    })
                }
            } else {
                this[objectName].list[i].selected = !this[objectName].list[i].selected;
            }

            this.$forceUpdate();
            this.getActionButton();
        },
        getSelectAllStatus(objectName) {
            const objectCount = this[objectName].list.length;
            let selectedObjectCount = 0;
            this[objectName].list.forEach(object => {
                if (object.selected) {
                    selectedObjectCount += 1;
                }
            })
            return objectCount === selectedObjectCount && objectCount !== 0;
        },
        getActionButton() {
            let selectedSegmentCount = 0;
            this.segments.list.forEach(segment => {
                if (segment.selected) {
                    selectedSegmentCount += 1;
                }
            })

            this.bulk = selectedSegmentCount !== 0;
        },
        onClickSegment(event, segment) {
            if (!shouldOpenSlideIn(event.target)) {
                return;
            }

            const segmentUniqueId = segment.segmentUniqueId;

            this.$store.commit('backdrop/show');
            this.update.active = true;
            this.$store.dispatch('SEGMENT/GET', {segmentUniqueId});
            this.$store.dispatch('CONTACT/LIST', {
                filter: {"genSegmentContactRels.genSegment.gen_segment_unique_id": segmentUniqueId},
                order: {meta_insert_dt: "DESC"},
                page: 1,
                itemsPerPage: null,
                limit: null
            });
        }
    },
    beforeMount() {
        this.listSegments()
    }
}
</script>

<style lang="scss" scoped>
.item-selected {
    background-color: rgba(0, 0, 0, 0.075);
}
</style>
