<template>
    <component-slide-in :class="{ active }" @close="close" :size="size">

        <template v-if="!loading.SEGMENT_GET && !loading.SEGMENT_UPDATE && Object.keys(segment).length > 0">
            <div class="d-flex align-items-center mb-3">
                <component-avatar>{{ segment.segmentDescShort.substr(0, 1).toUpperCase() }}</component-avatar>
                <component-slide-in-title :editable="false"
                                          :subtitle="segment.segmentDescLong"
                                          :title="segment.segmentDescShort"
                                          :uuid="segment.segmentUniqueId"
                                          class="ms-3 flex-grow-1"/>
            </div>

            <nav class="nav nav-tabs mb-3">
                <button id="nav-general-tab"
                        aria-controls="nav-general"
                        aria-selected="false"
                        class="nav-link"
                        :class="{active: tab === 'general'}"
                        data-bs-target="#nav-general"
                        data-bs-toggle="tab"
                        @click.prevent="tab = 'general'; size = ''"
                        role="tab"
                        type="button">
                    {{ $t('general') }}
                </button>
                <button id="nav-contacts-tab"
                        aria-controls="nav-contacts"
                        aria-selected="false"
                        class="nav-link"
                        :class="{active: tab === 'contacts'}"
                        data-bs-target="#nav-contacts"
                        data-bs-toggle="tab"
                        @click.prevent="tab = 'contacts'; size = 'lg'"
                        role="tab"
                        type="button">
                    {{ $tc('contact', 2) }}
                </button>
                <button id="nav-references-tab"
                        aria-controls="nav-references"
                        aria-selected="false"
                        class="nav-link"
                        :class="{active: tab === 'references'}"
                        data-bs-target="#nav-references"
                        data-bs-toggle="tab"
                        @click.prevent="tab = 'references'; size = ''"
                        role="tab"
                        type="button">
                    {{ $tc('reference', 2) }}
                </button>
                <button id="nav-comments-tab"
                        aria-controls="nav-comments"
                        aria-selected="false"
                        class="nav-link"
                        :class="{active: tab === 'comments'}"
                        data-bs-target="#nav-comments"
                        data-bs-toggle="tab"
                        @click.prevent="tab = 'comments'; size = ''"
                        role="tab"
                        type="button">
                    {{ $tc('comment', 2) }}
                </button>
                <button id="nav-activity-tab"
                        aria-controls="nav-activity"
                        aria-selected="false"
                        class="nav-link"
                        :class="{active: tab === 'activity'}"
                        data-bs-target="#nav-activity"
                        data-bs-toggle="tab"
                        @click.prevent="tab = 'activity'; size = ''"
                        role="tab"
                        type="button">
                    {{ $tc('activity', 1) }}
                </button>
            </nav>

            <div id="nav-tabContent" class="tab-content">
                <div id="nav-general"
                     aria-labelledby="nav-general-tab"
                     class="tab-pane fade"
                     :class="{'active show': tab === 'general'}"
                     role="tabpanel">
                    <form @submit.prevent="onUpdateSegment">
                        <div class="row">
                            <div class="col-6">
                                <div class="mb-1">
                                    <label class="form-label small"
                                           for="segment-desc-short">{{ $t('short-name') }}</label>
                                    <input id="segment-desc-short"
                                           v-model="descShort"
                                           :aria-label="$t('short-name')"
                                           :class="{'is-invalid': this.$v.descShort.$invalid}"
                                           :disabled="!edit.flag"
                                           aria-describedby="segment-desc-short"
                                           class="form-control form-control-sm"
                                           type="text">
                                    <div v-if="!this.$v.descShort.required" class="invalid-feedback">
                                        {{ $t('validation.error.required.desc-short') }}
                                    </div>
                                    <div v-if="!this.$v.descShort.maxLength" class="invalid-feedback">
                                        {{ $t('validation.error.maxLength.desc-short', {count: $v.descShort.$params.maxLength.max}) }}
                                    </div>
                                </div>
                                <div class="mb-1">
                                    <label class="form-label small"
                                           for="segment-desc-long">{{ $tc('name', 1) }}</label>
                                    <input id="segment-desc-long"
                                           v-model="descLong"
                                           :aria-label="$tc('name', 1)"
                                           :class="{'is-invalid': this.$v.descLong.$invalid}"
                                           :disabled="!edit.flag"
                                           aria-describedby="segment-desc-long"
                                           class="form-control form-control-sm"
                                           type="text">
                                    <div v-if="!this.$v.descLong.maxLength" class="invalid-feedback">
                                        {{ $t('validation.error.maxLength.desc-long', {count: $v.descLong.$params.maxLength.max}) }}
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label class="form-label small"
                                           for="segment-comment">{{ $tc('description', 1) }}</label>
                                    <textarea id="segment-comment"
                                              v-model="comment"
                                              :aria-label="$tc('last-name', 1)"
                                              :class="{'is-invalid': this.$v.comment.$invalid}"
                                              :disabled="!edit.flag"
                                              aria-describedby="last-name"
                                              class="form-control form-control-sm"
                                              rows="6"
                                              type="text"></textarea>
                                    <div v-if="!this.$v.comment.maxLength" class="invalid-feedback">
                                        {{ $t('validation.error.maxLength.comment', {count: $v.comment.$params.maxLength.max}) }}
                                    </div>
                                </div>

                                <p>
                                    <button v-if="!edit.flag"
                                            class="btn btn-sm btn-outline-primary me-2"
                                            type="button"
                                            @click="onClickEdit">
                                        {{ $t('actions.edit') }}
                                    </button>
                                    <button v-if="edit.flag"
                                            :disabled="this.$v.$invalid"
                                            class="btn btn-sm btn-outline-success me-2">
                                        {{ $t('actions.update') }}
                                    </button>
                                    <button v-if="edit.flag"
                                            class="btn btn-sm btn-outline-danger"
                                            type="button"
                                            @click="edit.flag = false">
                                        {{ $t('actions.cancel') }}
                                    </button>
                                </p>
                            </div>
                        </div>
                    </form>
                </div>
                <div id="nav-contacts"
                     aria-labelledby="nav-contacts-tab"
                     class="tab-pane fade"
                     :class="{'active show': tab === 'contacts'}"
                     role="tabpanel">
                    <button :disabled="selectedContacts().length === 0"
                            class="btn btn-primary mb-3"
                            @click="onRemoveContactFromSegment(selectedContacts())">
                        {{ $t('actions.remove-from-segment') }}
                    </button>

                    <table class="table">
                        <thead>
                        <tr>
                            <th scope="col">
                                <button class="btn btn-checkbox" @click="toggleCheckbox('ALL', 'contacts')">
                                    <font-awesome-icon
                                        :class="getSelectAllStatus('contacts') ? 'text-primary' : ''"
                                        :icon="getSelectAllStatus('contacts') ? ['fas', 'check-square'] : ['far', 'square']"/>
                                </button>
                            </th>
                            <th scope="col">{{ $tc('salutation', 1) }}</th>
                            <th scope="col">{{ $tc('first-name', 1) }}</th>
                            <th scope="col">{{ $tc('last-name', 1) }}</th>
                            <th scope="col">{{ $tc('company', 1) }}</th>
                            <th scope="col">{{ $tc('street', 1) }}</th>
                            <th scope="col">{{ $tc('postcode', 1) }}</th>
                            <th scope="col">{{ $tc('city', 1) }}</th>
                            <th scope="col">{{ $tc('country', 1) }}</th>
                            <th scope="col">{{ $tc('email', 1) }}</th>
                            <th scope="col">{{ $tc('action', 1) }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-cloak v-if="!loading.CONTACT_LIST && contacts.list.length > 0">
                            <tr v-for="(contact, i) in contacts.list" :key="'contact-' + i">
                                <td>
                                    <button class="btn btn-checkbox" @click="toggleCheckbox(i, 'contacts')">
                                        <font-awesome-icon
                                            :class="contact.selected ? 'text-primary' : ''"
                                            :icon="contact.selected ? ['fas', 'check-square'] : ['far', 'square']"/>
                                    </button>
                                </td>
                                <td>{{ contact.address.salutation }}</td>
                                <td>{{ contact.address.firstName }}</td>
                                <td>{{ contact.address.lastName }}</td>
                                <td>{{ contact.address.company }}</td>
                                <td>{{ contact.address.street }}</td>
                                <td>{{ contact.address.postcode }}</td>
                                <td>{{ contact.address.city }}</td>
                                <td>
                                    <span v-if="contact.address.country">
                                        {{ countries[contact.address.country].name }}
                                    </span>
                                </td>
                                <td>{{ contact.contactInformation.email }}</td>
                                <td class="text-center">
                                    <button class="btn p-0 text-primary"
                                            @click="onRemoveContactFromSegment([contact.contactUniqueId])">
                                        <font-awesome-icon :icon="['far', 'trash-alt']"/>
                                    </button>
                                </td>
                            </tr>
                        </template>
                        <template v-cloak v-if="!loading.CONTACT_LIST && contacts.list.length === 0">
                            <tr>
                                <td colspan="99">
                                    <div
                                        class="py-3 text-primary d-flex align-items-center justify-content-center flex-column mb-0"
                                        role="alert">
                                        <font-awesome-icon icon="exclamation-triangle" size="3x"/>
                                        <div class="mt-2 fs-4">{{ $t('pages.contacts.no-contacts-found') }}</div>
                                    </div>
                                </td>
                            </tr>
                        </template>
                        <template v-if="loading.CONTACT_LIST">
                            <tr v-for="n in 10" :key="'row-shimmer-' + n">
                                <td class="align-middle">
                                    <component-shimmer height="14px" style="margin: 4px 0;" width="14px"/>
                                </td>
                                <td class="align-middle">
                                    <component-shimmer width="100%"/>
                                </td>
                                <td class="align-middle">
                                    <component-shimmer width="100%"/>
                                </td>
                                <td class="align-middle">
                                    <component-shimmer width="100%"/>
                                </td>
                                <td class="align-middle">
                                    <component-shimmer width="100%"/>
                                </td>
                                <td class="align-middle">
                                    <component-shimmer width="100%"/>
                                </td>
                                <td class="align-middle">
                                    <component-shimmer width="100%"/>
                                </td>
                                <td class="align-middle">
                                    <component-shimmer width="100%"/>
                                </td>
                                <td class="align-middle">
                                    <component-shimmer width="100%"/>
                                </td>
                                <td class="align-middle">
                                    <component-shimmer width="100%"/>
                                </td>
                            </tr>
                        </template>
                        </tbody>
                    </table>
                </div>
                <div id="nav-references"
                     aria-labelledby="nav-references-tab"
                     class="tab-pane fade"
                     :class="{'active show': tab === 'references'}"
                     role="tabpanel">
                    <div class="table-responsive">
                        <table class="table">
                            <tbody>
                            <tr>
                                <td class="border-bottom-0" colspan="99">
                                    <div
                                        class="py-3 text-primary d-flex align-items-center justify-content-center flex-column mb-0"
                                        role="alert">
                                        <font-awesome-icon icon="exclamation-triangle" size="3x"/>
                                        <div class="mt-2 fs-4">{{ $t('no-references-found') }}</div>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div id="nav-comments"
                     aria-labelledby="nav-comments-tab"
                     class="tab-pane fade"
                     :class="{'active show': tab === 'comments'}"
                     role="tabpanel">
                    <div class="table-responsive">
                        <table class="table">
                            <tbody>
                            <tr>
                                <td class="border-bottom-0" colspan="99">
                                    <div
                                        class="py-3 text-primary d-flex align-items-center justify-content-center flex-column mb-0"
                                        role="alert">
                                        <font-awesome-icon icon="exclamation-triangle" size="3x"/>
                                        <div class="mt-2 fs-4">{{ $t('no-comments-found') }}</div>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div id="nav-activity"
                     aria-labelledby="nav-activity-tab"
                     class="tab-pane fade"
                     :class="{'active show': tab === 'activity'}"
                     role="tabpanel">
                    <div class="list-group">
                        <div v-if="segment.meta_update_dt" class="list-group-item">
                            <div class="d-flex w-100 justify-content-between align-items-center">
                                <p class="mb-0">{{ $t('activity-logs.segment-updated') }}</p>
                                <small>{{ segment.meta_update_dt | timestamp }}</small>
                            </div>
                        </div>
                        <div class="list-group-item">
                            <div class="d-flex w-100 justify-content-between align-items-center">
                                <p class="mb-0">{{ $t('activity-logs.segment-created') }}</p>
                                <small>{{ segment.meta_insert_dt | timestamp }}</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <template v-else>
            <div class="d-flex align-items-center mb-3">
                <component-shimmer class="rounded-circle d-block me-3" height="64px" width="64px"/>
                <div>
                    <component-shimmer class="mb-1"/>
                    <component-shimmer width="241.8px"/>
                </div>
            </div>

            <div class="d-flex mb-3">
                <component-shimmer class="me-2" height="37.2px" width="100px"/>
                <component-shimmer class="me-2" height="37.2px" width="100px"/>
                <component-shimmer class="me-2" height="37.2px" width="100px"/>
            </div>

            <div class="mb-3">
                <component-shimmer height="33.2px" width="162px"/>
            </div>

            <table class="table">
                <thead>
                <tr>
                    <th scope="col">
                        <button class="btn btn-checkbox" @click="toggleCheckbox('ALL', 'contacts')">
                            <font-awesome-icon
                                :class="getSelectAllStatus('contacts') ? 'text-primary' : ''"
                                :icon="getSelectAllStatus('contacts') ? ['fas', 'check-square'] : ['far', 'square']"/>
                        </button>
                    </th>
                    <th scope="col">{{ $tc('salutation', 1) }}</th>
                    <th scope="col">{{ $tc('first-name', 1) }}</th>
                    <th scope="col">{{ $tc('last-name', 1) }}</th>
                    <th scope="col">{{ $tc('company', 1) }}</th>
                    <th scope="col">{{ $tc('street', 1) }}</th>
                    <th scope="col">{{ $tc('postcode', 1) }}</th>
                    <th scope="col">{{ $tc('city', 1) }}</th>
                    <th scope="col">{{ $tc('country', 1) }}</th>
                    <th scope="col">{{ $tc('email', 1) }}</th>
                    <th scope="col">{{ $tc('action', 1) }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="n in 10" :key="'row-shimmer-' + n">
                    <td class="align-middle">
                        <component-shimmer height="14px" style="margin: 4px 0;" width="14px"/>
                    </td>
                    <td class="align-middle">
                        <component-shimmer width="100%"/>
                    </td>
                    <td class="align-middle">
                        <component-shimmer width="100%"/>
                    </td>
                    <td class="align-middle">
                        <component-shimmer width="100%"/>
                    </td>
                    <td class="align-middle">
                        <component-shimmer width="100%"/>
                    </td>
                    <td class="align-middle">
                        <component-shimmer width="100%"/>
                    </td>
                    <td class="align-middle">
                        <component-shimmer width="100%"/>
                    </td>
                    <td class="align-middle">
                        <component-shimmer width="100%"/>
                    </td>
                    <td class="align-middle">
                        <component-shimmer width="100%"/>
                    </td>
                    <td class="align-middle">
                        <component-shimmer width="100%"/>
                    </td>
                </tr>
                </tbody>
            </table>
        </template>

    </component-slide-in>
</template>

<script>
import ComponentSlideIn from "@/components/ui/ComponentSlideIn";
import ComponentShimmer from "@/components/ComponentShimmer";
import ComponentAvatar from "@/components/ui/ComponentAvatar";
import ComponentSlideInTitle from "@/components/ui/ComponentSlideInTitle";
import {countries} from "countries-list";
import {maxLength, required} from "vuelidate/lib/validators";

export default {
    name: "ComponentUpdateSegment",
    components: {
        ComponentSlideInTitle,
        ComponentAvatar,
        ComponentShimmer,
        ComponentSlideIn
    },
    computed: {
        v() {
            return this.$v;
        },
        loading() {
            return {
                CONTACT_LIST: this.$store.state.loading.CONTACT_LIST,
                SEGMENT_GET: this.$store.state.loading.SEGMENT_GET,
                SEGMENT_UPDATE: this.$store.state.loading.SEGMENT_UPDATE
            }
        },
        segment() {
            return this.$store.state.SEGMENT.segment;
        },
        contacts() {
            return this.$store.state.CONTACT.contacts;
        },
        descShort: {
            get() {
                return this.edit.flag ? this.edit.descShort : this.segment.segmentDescShort;
            },
            set(segmentDescShort) {
                this.edit.descShort = segmentDescShort;
            }
        },
        descLong: {
            get() {
                return this.edit.flag ? this.edit.descLong : this.segment.segmentDescLong;
            },
            set(segmentDescLong) {
                this.edit.descLong = segmentDescLong;
            }
        },
        comment: {
            get() {
                return this.edit.flag ? this.edit.comment : this.segment.segmentComment;
            },
            set(segmentComment) {
                this.edit.comment = segmentComment;
            }
        }
    },
    props: {
        active: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            countries,
            size: '',
            tab: 'general',
            edit: {
                flag: false,
                descShort: '',
                descLong: '',
                comment: ''
            }
        }
    },
    methods: {
        close() {
            this.$store.commit('backdrop/hide');
            this.$emit('close');
        },
        onUpdateSegment() {
            this.$store.dispatch('SEGMENT/UPDATE', {
                segmentUniqueId: this.segment.segmentUniqueId,
                segmentDescShort: this.descShort,
                segmentDescLong: this.descLong,
                segmentComment: this.comment
            })
                .then(segment => {
                    this.edit.flag = false;
                    this.$emit('update', segment);
                    this.$gtag.event('SEGMENT_UPDATE');
                })
                .catch(error => {
                    this.$store.dispatch('toast/danger', {
                        title: this.$tc('toast.danger.error'),
                        message: error.response.data.message
                    });
                });
        },
        getSelectAllStatus(objectName) {
            const objectCount = this[objectName].list.length;
            let selectedObjectCount = 0;
            this[objectName].list.forEach(object => {
                if (object.selected) {
                    selectedObjectCount += 1;
                }
            })
            return objectCount === selectedObjectCount && objectCount !== 0;
        },
        selectedContacts() {
            let selectedContacts = this.contacts.list.map(contact => {
                if (contact.selected === true) {
                    return contact.contactUniqueId
                }
            })
            return selectedContacts.filter(Boolean);
        },
        onRemoveContactFromSegment(contactUniqueId) {
            const segmentUniqueId = this.segment.segmentUniqueId;
            this.$store.dispatch('SEGMENT/REMOVE_CONTACT', {segmentUniqueId, contactUniqueId});
            this.$store.dispatch('CONTACT/LIST', {
                filter: {"genSegmentContactRels.genSegment.gen_segment_unique_id": segmentUniqueId},
                order: {meta_insert_dt: "DESC"},
                page: 1,
                itemsPerPage: 25,
            })
                .then(() => {
                    this.$store.dispatch('toast/success', {
                        title: this.$tc('toast.success.success'),
                        message: this.$tc('toast.success.segment-contact-removed')
                    });
                    this.$gtag.event('SEGMENT_REMOVE_CONTACT');
                })
                .catch(() => {
                    this.$store.dispatch('toast/danger', {
                        title: this.$tc('toast.danger.error'),
                        message: this.$tc('toast.danger.segment-contact-removed')
                    });
                });
        },
        toggleCheckbox(i, objectName) {
            if (i === 'ALL') {
                if (this.getSelectAllStatus(objectName)) {
                    this[objectName].list.forEach(object => {
                        object.selected = false;
                    })
                } else {
                    this[objectName].list.forEach(object => {
                        object.selected = true;
                    })
                }
            } else {
                this[objectName].list[i].selected = !this[objectName].list[i].selected;
            }

            this.$forceUpdate();
        },
        onClickEdit() {
            this.edit.descShort = this.segment.segmentDescShort;
            this.edit.descLong = this.segment.segmentDescLong;
            this.edit.comment = this.segment.segmentComment;
            this.edit.flag = true;
        },
        closeOnEscape(e) {
            if (e.key === 'Escape' && this.active === true) {
                this.close();
            }
        }
    },
    validations: {
        descShort: {
            maxLength: maxLength(50),
            required
        },
        descLong: {
            maxLength: maxLength(255)
        },
        comment: {
            maxLength: maxLength(4000)
        },
    },
    watch: {
        active(newValue) {
            if (newValue === true) {
                window.addEventListener('keydown', this.closeOnEscape);
            } else {
                window.removeEventListener('keydown', this.closeOnEscape);
            }
        }
    }
}
</script>

<style scoped>

</style>
